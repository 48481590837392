<template>
  <div :class="`m-3 p-2 wrapper wrapper--${bu}`">
    <Loading v-if="loading" />
    <Tabs v-else>
      <Tab title="Totaal">
        <table :class="`w-100 table--default table__border--${bu}`">
          <thead :class="`table__head--${bu}`">
            <tr>
              <th class="table__head--text">Inkoper</th>
              <th class="table__head--text">Aantal</th>
              <th class="table__data--right table__head--text">Kostprijs</th>
              <th class="table__data--right table__head--text">Vraagprijs</th>
              <th class="table__data--right table__head--text">Nettoprijs</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, key) in sold_per_buyer_totals"
              :key="key"
              :class="`table__row--color`"
            >
              <td class="table__cell--default">{{ key }}</td>
              <td class="table__cell--default table__data--right ">{{ item.aantal }}</td>
              <td class="table__data--right table__cell--default">
                € {{ item.kostprijs }}
              </td>
              <td class="table__data--right table__cell--default">
                € {{ item.vraagprijs }}
              </td>
              <td class="table__data--right table__cell--default">
                € {{ item.nettoprijs }}
              </td>
            </tr>
          </tbody>

          <tfoot class="table__foot">
            <tr>
              <th class="table__foot--padding">Totaal:</th>
              <th class="table__data--right table__foot--padding">
                {{ sold_per_buyer_totals_totals.aantal }}
              </th>
              <th class="table__data--right table__foot--padding">
                € {{ sold_per_buyer_totals_totals.kostprijs }}
              </th>
              <th class="table__data--right table__foot--padding">
                € {{ sold_per_buyer_totals_totals.vraagprijs }}
              </th>
              <th class="table__data--right table__foot--padding">
                € {{ sold_per_buyer_totals_totals.nettoprijs }}
              </th>
            </tr>
          </tfoot>
        </table>
      </Tab>
      <Tab v-for="(buyer, key) in sold_per_buyer" :key="key" :title="`${key}`">
        <SoldPerBuyerTable :table_data="buyer" :table_style="bu" />
      </Tab>
    </Tabs>
  </div>
</template>

<script>
import request from "@/functions/request.js";
import Loading from "@/components/Loading.vue";
import Tab from "@/components/Tabs/Tab.vue";
import Tabs from "@/components/Tabs/Tabs.vue";
import SoldPerBuyerTable from "@/components/SoldPerBuyerTable.vue"
export default {
  props: ["bu"],
  components: { Loading, Tab, Tabs, SoldPerBuyerTable },
  data: () => ({
    loading: true,
    sold_per_buyer: null,
    sold_per_buyer_totals: null,
    sold_per_buyer_totals_totals: null,
  }),
  created() {
    this.getData(this.bu);
  },
  watch: {
    bu(newbu){
        this.getData(newbu);
        this.loading = true
    }
  },
  methods: {
    getData() {
      request(`sold-per-buyer/${this.bu}`, "GET").then(
        ({ sold_per_buyer, sold_per_buyer_totals, sold_per_buyer_totals_totals }) => {
          this.sold_per_buyer = sold_per_buyer;
          this.sold_per_buyer_totals = sold_per_buyer_totals;
          this.sold_per_buyer_totals_totals = sold_per_buyer_totals_totals;
          this.loading = false;
        }
      );
    },
  },
};
</script>
