<template>
  <div>
    <table :class="`w-100 table--default table__border--${table_style}`">
      <thead :class="`table__head--${table_style}`">
        <tr>
          <th class="table__head--text">Foto</th>
          <th class="table__head--text">Item</th>
          <th class="table__data--right table__head--text">Klant</th>
          <th class="table__data--right table__head--text">Kostprijs</th>
          <th class="table__data--right table__head--text">Vraagprijs</th>
          <th class="table__data--right table__head--text">Nettoprijs</th>
          <th class="table__data--right table__head--text">Betaald</th>
          <th class="table__data--right table__head--text">Datum</th>
          <th class="table__data--right table__head--text">Lev</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, key) in table_data"
          :key="key"
          :class="`table__row--color`"
        >
          <td class="table__cell--default">
            <img
              :src="`https://apps.kleyn.com:1919/prod/public/data/mainimage/${item.itemno}/thumb`"
              class="table__picture--maxwidth"
            />
          </td>
          <td class="table__cell--default">
            <ImageHover :itemnummer="item.itemno" :bu="item.bu" />
            {{ item.soortnaam }}<br />
            {{ item.opbouwnaam }}<br />
            {{ item.merktype }}<br />
            {{ item.regjaar }}
          </td>
          <td class="table__cell--default">
            <router-link
              :to="{ name: 'klantpagina', params: { id: item.klant } }"
              >{{ item.klant }}</router-link
            ><br />
            {{ item.naam }}<br />
            {{ item.adres }}<br />
            {{ item.stad }}<br />
            {{ item.landnaam }}
          </td>
          <td class="table__data--right table__cell--default">
            € {{ item.kostprijs }}
          </td>
          <td class="table__data--right table__cell--default">
            € {{ item.vraagprijs }}
          </td>
          <td class="table__data--right table__cell--default">
            € {{ item.nettoprijs }}
          </td>
          <td class="table__data--right table__cell--default">
            € {{ item.betaald }}
          </td>
          <td class="table__data--right table__cell--default">
            {{ item.datum }}
          </td>
          <td class="table__data--right table__cell--default">
            {{ item.leveringsvoorwaarde }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ImageHover from "@/components/ImageHover.vue"
export default {
    components: { ImageHover },
    props: {
        table_data: [Object, Array],
        table_style: [Number, String],
    }
}
</script>
